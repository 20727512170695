import React from "react";
import HeadManager from "./HeadManager";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Preloader from "./Preloader";
import CookieConsent from "react-cookie-consent";
import Link from "@/components/Links/ActiveLink";
import { ToastContainer } from "react-toastify";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { protectedPages, isPathMatched } from "@/lib/protected";
import baseUrl from "@/utils/baseUrl";
import axios from "axios";
import useSWR from "swr";

const fetcher = (args) => axios.get(...args).then((res) => res.data);

const Layout = ({ children }) => {
  const [loader, setLoader] = React.useState(true);
  const router = useRouter();
  const { data: session, status } = useSession();
  const {
    data: user,
    error,
    isLoading,
  } = useSWR(session ? [`${baseUrl}/api/v1/auth/account`] : null, fetcher, {
    shouldRetryOnError: false,
  });

  React.useEffect(() => {
    const handleRouteChange = (url) => {
      if (url !== router.pathname) {
        setLoader(true);
      }
    };

    if (status !== "loading") {
      if (status === "unauthenticated" || (status === "authenticated" && !isLoading)) {
        setLoader(false);
      }
    }

    if (session && router.pathname === "/authentication") {
      router.push("/");
    } else if (router.pathname !== "/logout" && (error?.response?.status === 401 || error?.response?.status === 403)) {
      router.push("/logout");
    } else if (status !== "loading" && !session && isPathMatched(router.pathname, protectedPages)) {
      router.push("/authentication");
    }

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.pathname, router.events, session, error, status, isLoading, router.query]);

  return (
    <React.Fragment>
      <HeadManager pathname={router.pathname} />
      {loader && <Preloader />}
      <ToastContainerComponents />
      <Navbar user={user} />
      <MainContainer user={user}>{children}</MainContainer>
      <Footer />
      <CookieConsentComponents />
    </React.Fragment>
  );
};

const ToastContainerComponents = () => (
  <ToastContainer
    stacked
    position="top-center"
    closeOnClick
    theme="colored"
    style={toastContainerStyles}
    hideProgressBar={true}
  />
);

const MainContainer = ({ children, user, update }) => (
  <div id="main-container" className="main-container">
    {React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { user, update });
      }
      return child;
    })}
  </div>
);

const CookieConsentComponents = () => (
  <CookieConsent
    buttonText="Accept All"
    location="bottom"
    containerClasses="cookie-consent-container"
    buttonClasses="cookie-consent-button"
    buttonStyle={cookieButtonStyles}
    style={cookieConsentStyles}
    cookieName="cp_cookie_consent"
    declineButtonStyle={declineButtonStyle}
    contentStyle={contentStyles}
  >
    <div style={cookieTitleStyle} className="cookie-consent-title">
      🍪 This site uses cookies
    </div>
    <p>
      By using this website, you agree to our{" "}
      <Link legacyBehavior href="/cookie-policy" activeClassName="active">
        <a style={policyStyles}>Cookie Policy</a>
      </Link>
      .
    </p>
  </CookieConsent>
);

const cookieTitleStyle = { alignSelf: "baseline", marginBottom: "10px", fontSize: "20px" };
const declineButtonStyle = { display: "none" };
const toastContainerStyles = { maxWidth: "380px", width: "100%" };
const cookieConsentStyles = {
  background: "#fff",
  color: "#333",
  borderRadius: "8px",
  boxShadow: "0 0 18px rgb(0 0 0 / 20%)",
  padding: "15px",
  width: "auto",
  maxWidth: "380px",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "20px",
};

const cookieButtonStyles = {
  background: "#b163f1",
  color: "white",
  fontWeight: "700",
  padding: "8px 15px",
  margin: "10px 0",
  borderRadius: "1.3em",
  marginLeft: "15px",
};

const contentStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flex: "1 1 300px",
};

const policyStyles = {
  color: "#f69220",
};

export default Layout;
