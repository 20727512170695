import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import baseUrl from "@/utils/baseUrl";
import catchErrors from "@/utils/catchErrors";
import { toast } from "react-toastify";
import { StreamChat } from "stream-chat";
import Drawer from "react-modern-drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import PageBannerLoading from "@/components/Common/PageBannerLoading";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSession } from "next-auth/react";
import "react-modern-drawer/dist/index.css";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";

import {
  Chat,
  Channel,
  Thread,
  MessageInput,
  Window,
  MessageInputFlat,
  ChannelList,
  useChannelStateContext,
  VirtualizedMessageList,
} from "stream-chat-react";

const options = { state: true, presence: true, limit: 10 };
const sort = { last_message_at: -1 };

const Messages = () => {
  const user = useSession()?.data?.user ?? null;
  const [client, setClient] = useState(null);
  const [filters, setFilters] = useState("");
  const [showChannelList, setShowChannelList] = useState(true);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [contacts, setContacts] = useState({});
  const [isOpenChat, setIsOpenChat] = useState(false);
  const drawerToggleCountRef = useRef(0);

  useEffect(() => {
    if (client) return;

    const newClient = StreamChat.getInstance(process.env.STREAM_API_KEY);
    let channels = []; // Store references to channels

    async function setupChat() {
      try {
        const streamResponse = await axios.get(`${baseUrl}/api/v1/stream/token-provider`);
        const { userToken, userId, profileId, profilePhoto, name } = streamResponse.data;

        const channelFilter = { type: "messaging", members: { $in: [profileId] } };
        setFilters(channelFilter);

        if (newClient.user === undefined) {
          await newClient.connectUser(
            {
              id: userId,
              name: name,
              image: profilePhoto || `${baseUrl}/images/advisor/advisor3.svg`,
            },
            userToken
          );
        }
        setClient(newClient);
        const response = await axios.get(`${baseUrl}/api/v1/user/contacts`);
        const { contacts } = response.data;
        const contactsMap = contacts.reduce((map, contact) => {
          map[contact.id] = { ...contact };
          return map;
        }, {});
        setContacts(contactsMap);

        let totalUnread = 0;
        channels = await newClient.queryChannels(channelFilter, sort, options);
        channels.forEach((channel) => {
          // Initial unread count for each channel
          totalUnread += channel.state.unreadCount;
          let channelUnreadCount = channel.state.unreadCount;

          // Listen for new messages
          channel.on("message.new", async (event) => {
            if (!event.message.user || event.message.user.id !== userId) {
              setTotalUnreadCount((prevUnreadCount) => prevUnreadCount + 1);
              channelUnreadCount++;
            }
            if (event.message.user.id === userId) {
              const { members } = await channel.queryMembers({}, {}, {});
              const recipient = members.filter((member) => member.user_id !== userId)[0].user_id;
              await axios.post(`${baseUrl}/api/v1/user/contacts/message/new`, { recipient, sender: userId });
            }
          });

          // Listen for read events
          channel.on("message.read", (event) => {
            if (event.user.id === userId) {
              setTotalUnreadCount((prevUnreadCount) => Math.max(prevUnreadCount - channelUnreadCount, 0));
              channelUnreadCount = 0;
            }
          });
        });
        setTotalUnreadCount(totalUnread);
      } catch (err) {
        catchErrors(err, toast.error);
      }
    }
    if (user) setupChat();
  }, [user, client]);

  const toggleChatDrawer = () => {
    setIsOpenChat((prevState) => !prevState);
    drawerToggleCountRef.current += 1;
    setShowChannelList(true);
  };

  const toggleChannelView = () => {
    setShowChannelList(!showChannelList);
  };

  const CustomChannelPreview = ({ channel, setActiveChannel }) => {
    const hasUnread = channel.state.unreadCount > 0; // Checks for unread messages
    return (
      <div
        className={`custom-channel-preview ${hasUnread ? "has-unread" : ""}`}
        onClick={() => {
          toggleChannelView();
          setActiveChannel(channel);
        }}
      >
        <img src={contacts[channel.id]?.profilePhoto || channel.data.image} alt="Channel" />
        <p>{contacts[channel.id]?.name || channel.data.name}</p>
        {hasUnread && <div className="unread-indicator">{channel.state.unreadCount}</div>}
      </div>
    );
  };

  const CustomChannelHeader = () => {
    const { channel } = useChannelStateContext();

    return (
      <div className="custom-channel-header">
        <ChevronLeftIcon onClick={toggleChannelView} fontSize="large" sx={{ cursor: "pointer" }} />
        <img src={contacts[channel.id]?.profilePhoto || channel.data.image} />
        <div className="message-channel-header">
          <p>{contacts[channel.id]?.name || channel.data.name}</p>
          <p className="channel-profile">@{contacts[channel.id]?.profileId}</p>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {user && (
        <>
          <Badge
            className="custom-badge"
            badgeContent={totalUnreadCount}
            color="error"
            onClick={toggleChatDrawer}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{ position: "fixed" }}
          >
            <IconButton color="primary" aria-label="chat messages">
              <QuestionAnswerRoundedIcon />
            </IconButton>
          </Badge>

          <Drawer open={isOpenChat} onClose={toggleChatDrawer} direction="bottom" size={"60vh"} className="chat-drawer">
            {client ? (
              <div className="chat-container">
                <div className="chat-header">
                  <h4 className="chat-title">Messages</h4>
                  <div className="chat-close" onClick={toggleChatDrawer}>
                    <CloseRoundedIcon />
                  </div>
                </div>
                <Chat client={client} theme={"str-chat__theme-light"}>
                  {showChannelList ? (
                    <ChannelList
                      sort={sort}
                      key={drawerToggleCountRef.current} // Use the count as a key
                      options={options}
                      filters={filters}
                      Preview={CustomChannelPreview}
                    />
                  ) : (
                    <Channel>
                      <Window>
                        <CustomChannelHeader />
                        <VirtualizedMessageList stickToBottomScrollBehavior="smooth" messageActions={[]} />
                        <MessageInput Input={MessageInputFlat} />
                      </Window>
                      <Thread />
                    </Channel>
                  )}
                </Chat>
              </div>
            ) : (
              <PageBannerLoading />
            )}
          </Drawer>
        </>
      )}
    </React.Fragment>
  );
};

export default Messages;
